import { FC, ReactNode } from "react";
import useDisclosure from "@/hooks/useDisclosure";
import HelpIcon from "../../assets/imgs/svg/icon-help.svg";

type Props = {
  label: string;
  renderDialog: (isOpen: boolean, close: VoidFunction) => ReactNode;
};

const HelpText: FC<Props> = ({ label, renderDialog }): JSX.Element => {
  const { isOpen, openHandler: open, closeHandler: close } = useDisclosure();

  return (
    <>
      <div className="flex items-center gap-4">
        <HelpIcon width={16} height={16} />
        <button className="underline text-sm" type="button" onClick={open}>
          {label}
        </button>
      </div>

      {renderDialog(isOpen, close)}
    </>
  );
};
export default HelpText;
