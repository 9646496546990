import clsx from "clsx";
import { FC, ReactNode } from "react";

type OrderNoteListItem = { label: string; notes: ReactNode[] };

type Props = {
  items: OrderNoteListItem[];
  olClassName?: string;
};

const OrderNoteList: FC<Props> = ({ items, olClassName }) => {
  return (
    <ol
      className={clsx(
        "grid gap-8 text-xs",
        "list-decimal list-inside",
        olClassName,
      )}
    >
      {items.map(({ label, notes }) => (
        <li key={label}>
          <span className="font-bold">{label}</span>
          <ul className="ps-5 mt-2 space-y-1 list-inside list-disc">
            {notes.map((note, index) => (
              <li key={index}>{note}</li>
            ))}
          </ul>
        </li>
      ))}
    </ol>
  );
};
export default OrderNoteList;
